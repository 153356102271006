var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "w": "full",
      "align-items": "center",
      "justify-content": "center",
      "grid-gap": "15px"
    }
  }, _vm._l(_vm.total, function (_, index) {
    return _c('c-box', {
      key: index,
      style: _vm.stepStyle,
      attrs: {
        "bg": index + 1 <= _vm.current ? 'primary.400' : 'gray.200',
        "rounded": "lg",
        "h": "8px"
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }