var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "id": "kuisioner",
      "pos": "relative",
      "w": "100%",
      "mx": "auto",
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['0.5rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Formulir Gizi',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['100%', '600px'],
      "mx": "auto",
      "bg": "#FFF",
      "p": ['1rem', '0px']
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "1200px",
      "mx": "auto"
    }
  }, [_c('Steps', {
    attrs: {
      "current": 5,
      "total": 6
    }
  }), _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "590px",
      "mx": "auto",
      "margin-top": ['16px', '24px']
    }
  }, [_c('c-box', {
    attrs: {
      "text-align": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-family": "roboto",
      "text-transform": "uppercase",
      "font-weight": "500",
      "font-size": ['16px', '18px'],
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Formulir Gizi (5/6) ")]), _c('c-heading', {
    attrs: {
      "font-weight": "700",
      "font-size": ['18px', '28px'],
      "line-height": ['27px', '42px'],
      "color": "black.900"
    }
  }, [_vm._v(" Riwayat Makan 24 Jam Terakhir ")])], 1), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "marginTop": "24px"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto, sans-serif",
      "font-weight": ['700', '500'],
      "font-size": ['14px', '18px'],
      "color": "#333333",
      "pb": ['16px', '20px']
    }
  }, [_vm._v(" Silahkan tuliskan makanan yang kamu konsumsi hari ini, juga termasuk minuman selain air mineral "), _c('c-text', {
    attrs: {
      "as": "span",
      "color": "#D32737"
    }
  }, [_vm._v(" * ")])], 1), _c('FormAccordionList', {
    attrs: {
      "allow-toggle": ""
    }
  }, [_c('FormAccordionListItem', {
    attrs: {
      "label": "Pagi",
      "is-error": !_vm.isSectionPagiValid
    }
  }, [_vm.breakfast_ ? _c('FormFood', {
    attrs: {
      "label": "Makan Pagi",
      "time": "Pukul 00:00 - 09:00",
      "placeholder-type": "food",
      "is-eat": _vm.breakfast_.isEat,
      "has-photo": _vm.breakfast_.hasPhoto,
      "description-food": _vm.breakfast_.descriptionFood,
      "photos": _vm.breakfast_.photos,
      "is-description-food-valid": !_vm.$v.breakfast_.descriptionFood.$invalid
    },
    on: {
      "update:is-eat": function updateIsEat(newValue) {
        return _vm.breakfast_.isEat = newValue;
      },
      "update:has-photo": function updateHasPhoto(newValue) {
        return _vm.breakfast_.hasPhoto = newValue;
      },
      "update:description-food": function updateDescriptionFood(newValue) {
        return _vm.breakfast_.descriptionFood = newValue;
      },
      "update:photos": function updatePhotos(newValue) {
        return _vm.breakfast_.photos = newValue;
      }
    }
  }) : _vm._e(), _vm.morningSnack_ ? _c('FormFood', {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "label": "Snack/cemilan Pagi",
      "time": "Pukul 10:00 - 11:00",
      "placeholder-type": "snack",
      "is-eat": _vm.morningSnack_.isEat,
      "has-photo": _vm.morningSnack_.hasPhoto,
      "description-food": _vm.morningSnack_.descriptionFood,
      "photos": _vm.morningSnack_.photos,
      "is-description-food-valid": !_vm.$v.morningSnack_.descriptionFood.$invalid
    },
    on: {
      "update:is-eat": function updateIsEat(newValue) {
        return _vm.morningSnack_.isEat = newValue;
      },
      "update:has-photo": function updateHasPhoto(newValue) {
        return _vm.morningSnack_.hasPhoto = newValue;
      },
      "update:description-food": function updateDescriptionFood(newValue) {
        return _vm.morningSnack_.descriptionFood = newValue;
      },
      "update:photos": function updatePhotos(newValue) {
        return _vm.morningSnack_.photos = newValue;
      }
    }
  }) : _vm._e()], 1), _c('FormAccordionListItem', {
    attrs: {
      "label": "Siang",
      "is-error": !_vm.isSectionSiangValid
    }
  }, [_vm.lunch_ ? _c('FormFood', {
    attrs: {
      "label": "Makan Siang",
      "time": "Pukul 12:00 - 14:00",
      "placeholder-type": "food",
      "is-eat": _vm.lunch_.isEat,
      "has-photo": _vm.lunch_.hasPhoto,
      "description-food": _vm.lunch_.descriptionFood,
      "photos": _vm.lunch_.photos,
      "is-description-food-valid": !_vm.$v.lunch_.descriptionFood.$invalid
    },
    on: {
      "update:is-eat": function updateIsEat(newValue) {
        return _vm.lunch_.isEat = newValue;
      },
      "update:has-photo": function updateHasPhoto(newValue) {
        return _vm.lunch_.hasPhoto = newValue;
      },
      "update:description-food": function updateDescriptionFood(newValue) {
        return _vm.lunch_.descriptionFood = newValue;
      },
      "update:photos": function updatePhotos(newValue) {
        return _vm.lunch_.photos = newValue;
      }
    }
  }) : _vm._e(), _vm.afternoonSnack_ ? _c('FormFood', {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "label": "Snack/cemilan Siang",
      "time": "Pukul 15:00 - 17:00",
      "placeholder-type": "snack",
      "is-eat": _vm.afternoonSnack_.isEat,
      "has-photo": _vm.afternoonSnack_.hasPhoto,
      "description-food": _vm.afternoonSnack_.descriptionFood,
      "photos": _vm.afternoonSnack_.photos,
      "is-description-food-valid": !_vm.$v.afternoonSnack_.descriptionFood.$invalid
    },
    on: {
      "update:is-eat": function updateIsEat(newValue) {
        return _vm.afternoonSnack_.isEat = newValue;
      },
      "update:has-photo": function updateHasPhoto(newValue) {
        return _vm.afternoonSnack_.hasPhoto = newValue;
      },
      "update:description-food": function updateDescriptionFood(newValue) {
        return _vm.afternoonSnack_.descriptionFood = newValue;
      },
      "update:photos": function updatePhotos(newValue) {
        return _vm.afternoonSnack_.photos = newValue;
      }
    }
  }) : _vm._e()], 1), _c('FormAccordionListItem', {
    attrs: {
      "label": "Malam",
      "is-error": !_vm.isSectionMalamValid
    }
  }, [_vm.dinner_ ? _c('FormFood', {
    attrs: {
      "label": "Makan Malam",
      "time": "Pukul 18:00 - 20:00",
      "placeholder-type": "food",
      "is-eat": _vm.dinner_.isEat,
      "has-photo": _vm.dinner_.hasPhoto,
      "description-food": _vm.dinner_.descriptionFood,
      "photos": _vm.dinner_.photos,
      "is-description-food-valid": !_vm.$v.dinner_.descriptionFood.$invalid
    },
    on: {
      "update:is-eat": function updateIsEat(newValue) {
        return _vm.dinner_.isEat = newValue;
      },
      "update:has-photo": function updateHasPhoto(newValue) {
        return _vm.dinner_.hasPhoto = newValue;
      },
      "update:description-food": function updateDescriptionFood(newValue) {
        return _vm.dinner_.descriptionFood = newValue;
      },
      "update:photos": function updatePhotos(newValue) {
        return _vm.dinner_.photos = newValue;
      }
    }
  }) : _vm._e(), _vm.nightSnack_ ? _c('FormFood', {
    staticStyle: {
      "margin-top": "16px"
    },
    attrs: {
      "label": "Snack/cemilan Malam",
      "time": "Pukul 21:00 - 00:00",
      "placeholder-type": "snack",
      "is-eat": _vm.nightSnack_.isEat,
      "has-photo": _vm.nightSnack_.hasPhoto,
      "description-food": _vm.nightSnack_.descriptionFood,
      "photos": _vm.nightSnack_.photos,
      "is-description-food-valid": !_vm.$v.nightSnack_.descriptionFood.$invalid
    },
    on: {
      "update:is-eat": function updateIsEat(newValue) {
        return _vm.nightSnack_.isEat = newValue;
      },
      "update:has-photo": function updateHasPhoto(newValue) {
        return _vm.nightSnack_.hasPhoto = newValue;
      },
      "update:description-food": function updateDescriptionFood(newValue) {
        return _vm.nightSnack_.descriptionFood = newValue;
      },
      "update:photos": function updatePhotos(newValue) {
        return _vm.nightSnack_.photos = newValue;
      }
    }
  }) : _vm._e()], 1)], 1), _c('c-flex', {
    attrs: {
      "gap": "3",
      "margin-top": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "w": "100%",
      "h": "48px",
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "100px"
    },
    on: {
      "click": _vm.onPrevious
    }
  }, [_vm._v(" Sebelumnya ")]), _c('c-button', {
    attrs: {
      "type": "submit",
      "w": "100%",
      "h": "48px",
      "border-radius": "100px",
      "variant-color": "primary",
      "variant": "solid",
      "loading-text": "Submitting",
      "is-disabled": _vm.$v.$invalid,
      "is-loading": _vm.isSubmitting
    }
  }, [_vm._v(" Selanjutnya ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }